import { graphql, useStaticQuery } from "gatsby";

/*
    This function needs to be in the root src directory,
    so gatsby can find and resolve the data/query.
*/
export const useImageData = () => {
    return useStaticQuery(query);
};

const query = graphql`
    {
        logo: file(relativePath: { eq: "logo.png" }){
            childImageSharp {
                fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        hertzLogo: file(relativePath: { eq: "hertz.png" }){
            childImageSharp {
                fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        enterpriseLogo: file(relativePath: { eq: "enterprise.png" }){
            childImageSharp {
                fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;