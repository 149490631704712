module.exports = {
    brandInfo: {
        phoneNumber: "15878870645",
        contactEmail: "info@canadianauto.news",
        address: `4915 54 Street Red Deer AB`,
        copyright: `© ${new Date().getFullYear()} Canadian Auto News`,
        defaultCampaignId: "100",
    },

    images: {
        icon: "images/logo.png",
    },

    project: "can-credit-application",
    name: "Canadian Auto News",
    description: "Get the best rates ever",

    siteUrl: "https://www.canadianauto.news/",
    homeUrl: "/",
    termsUrl: "/terms_of_use",
    privacyPolicyUrl: "/privacy_policy",
    successUrl: "/success",

    hideSuccessCtas: true,

    layout: {
        component: require.resolve("./src/layout/index.jsx"),
        theme: require.resolve("./themes/custom/single-page-theme.js"),
    },
};