import React from "react";
import Typography from "@material-ui/core/Typography";

const DisclaimerText = () => {
    const typographyProps = {
        color: "primary",
        style: {
            fontSize: "13.5px",
            fontWeight: 900,
            display: "contents",
        },
    };

    return (
        <React.Fragment>
            All information collected in this application is required to properly pair you with
            the closest and best approval centre to submit your application, so you can gain access
            to <Typography {...typographyProps}>HUGE DEALS</Typography> and rental car inventory
        </React.Fragment>
    );
};

export default DisclaimerText;